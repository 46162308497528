import Vue from 'vue'
import Router from "vue-router";

Vue.use(Router)




const router = new Router({
  mode: "history",
  scrollBehavior (to, from, savedPosition) {
    to
    from 
    savedPosition
    return new Promise((resolve, reject) => {
      reject
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  },
  
    routes: [
      {
        path: '/',
        // name:'alkafeel',
      
        component: () => import('@/views/user/Index'),

        children: [
          {
            name: 'alkafeel',
            path: '',
            title:'alkafeel',
            component: () => import(/*webpackChunkName:"home" */'@/views/user/home.vue')
          }
          ,
        
        

   


      {
        path: '/history',
        name:'history',
      
        component:() => import(/*webpackChunkName:"history" */'@/views/user/history.vue'),
        
      },
      {
        path: '/abbas',
        name:'abbas',
        component:() => import(/*webpackChunkName:"Seraa" */'@/views/user/Seraa.vue'),
        
        
      },
      {
        path: '/description',
        name:'description',
        component:() => import(/*webpackChunkName:"description" */'@/views/user/description.vue'),
        
        
      },
      {
        path: '/muharram',
        name:'muharram',
        component:() => import(/*webpackChunkName:"muharram" */'@/views/user/muharram.vue'),
        
        
      },
      // {
      //   path: '/muharram_video',
      //   name:'muharram_video',
      //   component:() => import(/*webpackChunkName:"description" */'@/views/user/muharram_video.vue'),
        
        
      // },
      // {
      //   path: '/host',
      //   name:'host',
      //   component:() => import(/*webpackChunkName:"description" */'@/views/user/host.vue'),
        
        
      // },
      // {
      //   path: '/host_form',
      //   name:'host_form',
      //   component:() => import(/*webpackChunkName:"host" */'@/views/user/host_form.vue'),
      // },


      // {
      //   path: '/host',
      //   name:'host',
      //   component:() => import(/*webpackChunkName:"host" */'@/views/user/host.vue')
      // },
      
      //Muharram

      {
        path: '/articles',
        name:'news',
        component:() => import(/*webpackChunkName:"news" */'@/views/user/news/news.vue'),
        
        
      },

      {
        path: '/shuhadaById/:id',
        name:'shuhadaById',
        component:() => import(/*webpackChunkName:"news" */'@/views/user/shuhadaById.vue'),
        
        
      },


      //SaqiAward
      // {
      //   path: '/SaqiAward',
      //   name:'SaqiAward',
      //   component:() => import(/*webpackChunkName:"SaqiAward" */'@/views/user/SaqiAward.vue'),
      // },
      {
        path: '/shuhada',
        name:'shuhada',
        component:() => import(/*webpackChunkName:"news" */'@/views/user/shuhada.vue'),
        
        
      },
      // {
      //   path: '/news',
      //   name:'news',
      //   component:() => import(/*webpackChunkName:"news" */'./views/user/news.vue'),
        
        
      // },
      {
        path: '/newscat/:cat',
        name:'news_all',
        component:() => import(/*webpackChunkName:"news_all" */'@/views/user/news/news_all.vue'),
        
        
      },
      {
        path: '/newstag/:tag',
        name:'news_all',
        component:() => import(/*webpackChunkName:"news_all" */'@/views/user/news/news_all_tags.vue'),
        
        
      },
      {
        path: '/new/video',
        name:'news_video',
        component:() => import(/*webpackChunkName:"news_video" */'./views/user/news/news_video.vue'),
        
        
      },
      {
        path: '/new/photo',
        name:'news_photov2',
        component:() => import(/*webpackChunkName:"news_photo" */'@/views/user/news/news_photov2.vue'),
      },
      {
        path: '/new/infograph_inner/:id',
        name:'news_photo',
        component:() => import(/*webpackChunkName:"news_photo" */'@/views/user/news/Infograph_inner.vue'),
      },

    

      {
        path: '/new/photov2',
        name:'news_photov2',
        component:() => import(/*webpackChunkName:"news_photov2" */'@/views/user/news/news_photov2.vue'),
      },


      // {
      //   path: '/DeansAward',
      //   name:'DeansAward',
      //   component:() => import(/*webpackChunkName:"DeansAward" */'@/views/user/DeansAward.vue'),
      // },

      //DeansAward

      {
        path: '/new/reports',
        name:'news_report',
        component:() => import(/*webpackChunkName:"news_report" */'@/views/user/news/news_report.vue'),
      },
      {
        path: '/new/interviews',
        name:'news_interview',
        component:() => import(/*webpackChunkName:"news_interview" */'@/views/user/news/news_interview.vue'),
      },
      {
        path: '/new/literature',
        name:'news_literature',
        component:() => import(/*webpackChunkName:"news_literature" */'@/views/user/news/news_adeb.vue'),
      },
      {
        path: '/new/article',
        name:'news_article',
        component:() => import(/*webpackChunkName:"news_article" */'@/views/user/news/news_article.vue'),
      },






      {
        path: '/new/:id',
        name:'new',
        component:() => import(/*webpackChunkName:"news_inner" */'@/views/user/news/news_inner.vue'),
        
        
      },

      {
        path: '/contact',
        name:'contact',
        component:() => import(/*webpackChunkName:"contact" */'@/views/user/contact.vue'),
        
        
      },


      {
        path: '/stream',
        name:'live',
        component:() => import(/*webpackChunkName:"live" */'@/views/user/live.vue'),
      },
      {
        path: '/lib',
        name:'publications',
        component:() => import(/*webpackChunkName:"publications" */'@/views/user/publications.vue'),
      },
      {
        path: '/lib/:id',
        name:'publications',
        component:() => import(/*webpackChunkName:"book" */'@/views/user/book.vue'),
      },
      // {
      //   path: '/news/:id',
      //   name:'news',
      //   component:() => import(/*webpackChunkName:"search" */'@/views/user/news_inner'),
        
        
      // },

      {
        path: '/media',
        name:'videos',
        component:() => import(/*webpackChunkName:"videos" */'@/views/user/videos/videos.vue'),
        
        
      },
      {
        path: '/mediacat/:cat',
        name:'videos_cat',
        component:() => import(/*webpackChunkName:"news_all" */'@/views/user/videos/videos_cat.vue'),
        
        
      },
      {
        path: '/media/:id',
        name:'videos_inner',
        component:() => import(/*webpackChunkName:"videos" */'@/views/user/videos/videos_inner.vue'),
        
        
      },
      // {
      //   path: '/jobs2',
      //   name:'jobs',
      //   component:() => import(/*webpackChunkName:"jobs" */'@/views/user/jobs.vue'),
        
        
      // },

      {
        path: '/jobs',
        name:'jobs_media',
        component:() => import(/*webpackChunkName:"jobs_media" */'@/views/user/jobs_media.vue'),   
      },
      {
        path: '/fikria_projects',
        name:'fikria',
        component:() => import(/*webpackChunkName:"fikria" */'@/views/user/fikria.vue'),
      },
      {
        path: '/fikria/:id',
        name:'fikria_inner',
        component:() => import(/*webpackChunkName:"fikria" */'@/views/user/fikria_view.vue'),
      },

      {
        path: '/messages',
        name:'message',
        component:() => import(/*webpackChunkName:"message_i" */'@/views/user/message.vue'),
      },
      {
        path: '/structure',
        name:'structure',
      
        component:() => import(/*webpackChunkName:"structure" */'@/views/user/structure.vue'),
        
      },

      { path: "*",    component:() => import(/*webpackChunkName:"404" */'@/views/user/404_page.vue')}
    


      
      ]
      },
    

    ]
    });
    
    
    
    
    router.beforeEach((to, from, next) => {
     
        window.document.title = to.name ;
   
      next();
    });
    
    export default router;

